<template>
  <ResultDialogTemplate>
    <template #title><t path="pointshop_13">Purchase succeeded</t></template>
    <t as="p" path="pointshop_14" :args="{product: order.giftName}">
      {{ order.giftName }} has been purchased
    </t>
  </ResultDialogTemplate>
</template>

<script lang="ts">
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ExchangeResultDialog',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>
