<template>
  <DialogTemplate @cancel="$emit('close')">
    <template #title>
      <t path="">Buy</t>
    </template>
    <div class="flex-1 pt-24">
      <TheGiftItem :data="gift" no-btn />
      <div class="my-32 df-middle df-between">
        <t as="div" path="pointshop_10" :args="{points: changePoints}">
          <span class="f-lg f-bold mr-16">Total</span>
          <span class="f-lg f-bold mr-8 c-asst">{{changePoints}}</span>
          <span class="f-md c-tip">Points</span>
        </t>
        <div class="df-middle">
          <Icon :class="{'c-tip': numValue <= 1}" name="minus" @click="numValue > 1 && change(-1)" />
          <input type="number" class="buy-num" v-model="numValue" />
          <Icon :class="{'c-tip': numValue >= 20}" name="plus" @click="numValue < 20 && change(1)" />
        </div>
      </div>
    </div>
    <template #confirm>
      <Button class="flex-1 primary" @click="submit" :progress="progress">
        <t path="" #="{td}" custom>{{td || 'OK'}}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import { refreshAccount } from '@/modules/app/app'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, ref, shallowRef } from 'vue'
import useRequest from '@/hooks/useRequest'
import { exchange } from '@/modules/membership/member.api'
import { openDialog } from '@/components/popup/popup'
import ExchangeResultDialog from '@/modules/membership/components/ExchangeResultDialog.vue'
import TheGiftItem from '@/modules/membership/components/TheGiftItem.vue'
import Icon from '@/components/Icon.vue'
import { isPrime } from '@/state'

export default defineComponent({
  name: 'ExchangeDialog',
  components: { Icon, TheGiftItem, Button, DialogTemplate },
  emits: ['close'],
  props: {
    gift: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const numValue = ref(1)
    const [commit, progress] = useRequest(exchange)
    const basePoint = isPrime() ? props.gift.discountPoints : props.gift.poins
    const changePoints = shallowRef(isPrime() ? props.gift.discountPoints : props.gift.poins)

    const change = (dir: number) => {
      numValue.value = dir > 0 ? Number(numValue.value) + 1 : Number(numValue.value) - 1
      changePoints.value = basePoint * numValue.value
    }

    return {
      submit () {
        commit({
          giftId: props.gift.giftId,
          giftNum: numValue.value,
        }).then(() => {
          refreshAccount()
          ctx.emit('close', () => {
            openDialog(ExchangeResultDialog, { order: { giftNum: numValue.value, giftName: props.gift.giftName } })
          })
        })
      },
      progress,
      numValue,
      change,
      changePoints,
    }
  },
})
</script>

<style scoped lang="scss">
.buy-num {
  width: 64px;
  height: 24px;
  padding: 0;
  border: none;
  background: var(--color-light);
  text-align: center;
  outline: none;
  font-weight: bold;
  margin: 0 4px;
}
</style>
