<template>
<div class="df-between mb-12 item box">
  <div class="item-left">
    <Pic :src="data.giftPic" width="80" height="72" fit="cover" />
  </div>
  <div class="flex-1 df-between df-middle item-right pl-24 pr-16" :class="{normal: noBtn}">
    <div class="gift-info" :class="{normal: noBtn}">
      <p class="f-lg f-bold gift-name text-nowrap">{{data.giftName}}</p>
      <p class="mt-4">
        <!-- <span v-if="rebateRate !== 1 && data.levelRebateFlag === 1" class="c-tip mr-8 text-nowrap" style="text-decoration: line-through;">{{ data.poins }}</span>
        <t path="pointshop_9" :args="{points: finalPoints}">{{ finalPoints }} points</t> -->
        <span v-if="isPrime" class="c-tip mr-8 text-nowrap" style="text-decoration: line-through;">{{
            data.poins
          }}</span>
        <t path="pointshop_9" :args="{ points: isPrime ? data.discountPoints : data.poins }">
          {{isPrime ? data.discountPoints : data.poins}} points
        </t>
      </p>
    </div>
    <slot></slot>
  </div>
</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import state from '@/state'
import { mul } from 'essential/tools/math'
import Pic from '@/components/Pic.vue'
import { YesOrNo } from '@/types'

export default defineComponent({
  name: 'TheGiftItem',
  components: { Pic },
  props: {
    data: {
      type: Object,
      required: true,
    },
    noBtn: Boolean,
  },
  setup (props) {
    return {
      isPrime: computed(() => state.prime.member === YesOrNo.YES),
      rebateRate: computed(() => state.account.levelModel.rebateRate),
      finalPoints: computed(() => mul(props.data.poins, state.account.levelModel.rebateRate)),
    }
  },
})

</script>

<style scoped lang="scss">
.item {
  overflow: hidden;
  position: relative;
  height: 72px;
}

.item-left {
  width: 80px;
  overflow: hidden;
}

.gift-info:not(.normal) {
  max-width: calc(100% - 80px);
}

.gift-name {
  word-break: break-word;
}

.item-right {
  &:not(.normal) {
    max-width: calc(100% - 80px);
  }
}

@media only screen and (max-width: 320px) {
  .item-right {
    padding-left: 16px;
  }
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
