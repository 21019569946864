<template>
  <PageWithHeader>
    <template #title>
      <t path="pointshop_2">Points Store</t>
    </template>
    <div class="my-24 px-16">
     <!--<div class="mb-32 f-md c-text">-->
     <!--  <t as="p" path="" class="mb-12 f-bold">Rules</t>-->
     <!--  <div style="line-height: 18px;">-->
     <!--    <p class="mb-4">1. Coupons can only be used for transactions and cannot be withdrawn.</p>-->
     <!--    <p>2. There are no handling fees and overnight fees for using coupon transactions.</p>-->
     <!--  </div>-->
     <!--</div>-->
      <div class="df-middle df-between mb-16">
        <router-link custom #="{navigate}" to="/membership/level/points">
          <div @click="navigate">
            <t path="pointshop_3" class="c-title mr-8">Points</t>
            <span class="f-bold">{{ levelModel.validPoints }}</span>
            <Icon name="caret-right ml-8" class="c-tip" sm />
          </div>
        </router-link>
        <!-- <div>
          <t path="pointshop_4" class="c-title mr-8">Level</t>
          <span class="f-bold">{{ levelModel.level }}</span>
        </div> -->
        <div v-if="isPrime">
          <t path="pointshop_5" class="c-title mr-8">Discount</t>
          <!-- <span class="f-bold">{{ rebateRate }}%</span> -->
          <span class="f-bold">10%</span>
        </div>
      </div>
      <TheGiftList :list="giftList" />
    </div>
  </PageWithHeader>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import TheGiftList from '@/modules/membership/components/TheGiftList.vue'
import { Gifts, readGifts } from '@/modules/membership/member.api'
import state from '@/state'
import { mul, sub } from 'essential/tools/math'
import Icon from '@/components/Icon.vue'
import { YesOrNo } from '@/types'
import { refreshAccount } from '@/modules/app/app'

export default defineComponent({
  name: 'PointsStore',
  components: { TheGiftList, PageWithHeader, Icon },
  setup () {
    const giftList = ref<Array<Gifts> | null>(null)

    refreshAccount()

    readGifts({ page: 1, pageCount: 10 })
      .then(resp => giftList.value = resp as Array<Gifts>)

    return {
      giftList,
      isPrime: computed(() => state.prime.member === YesOrNo.YES),
      levelModel: computed(() => state.account.levelModel),
      rebateRate: computed(() => mul(sub(1, state.account.levelModel.rebateRate), 100)),
    }
  },
})
</script>
<style scoped lang="scss">

</style>
