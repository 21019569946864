<template>
  <Holder :content="list">
    <List :list-data="list" #="{ item }">
      <TheGiftItem :data="item">
        <Button class="buy df-middle line f-nm" icon="shopping" sm @click="buy(item)">
          <t path="pointshop_7" #="{td}" custom>{{ td || 'Buy' }}</t>
        </Button>
      </TheGiftItem>
    </List>
  </Holder>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import Button from '@/components/Button.vue'
import { Gifts } from '@/modules/membership/member.api'
import { openDialog } from '@/components/popup/popup'
import ExchangeDialog from '@/modules/membership/components/ExchangeDialog.vue'
import TheGiftItem from '@/modules/membership/components/TheGiftItem.vue'

export default defineComponent({
  name: 'TheGiftList',
  components: { TheGiftItem, Button, Holder, List },
  props: {
    list: {
      type: Array,
    },
  },
  setup () {
    const buy = (data: Gifts) => {
      openDialog(ExchangeDialog, { gift: data })
    }

    return {
      buy,
    }
  },
})

</script>

<style scoped lang="scss">

.buy {
  border-radius: 16px;
  padding:  0 12px;
  max-width: 80px;
  color: var(--color-primary);
  background-color: var(--color-primary-thin);
}

@media only screen and (max-width: 320px) {
  .buy {
    padding: 0 6px;
  }
}

</style>
